<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.FAQ") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                :rules="nameRules"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORMS.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="formModel.translations[selectedLocale.lang]">
              {{ $t("FORMS.text") }}
              <ckeditor
                v-model="formModel.translations[selectedLocale.lang].text"
                :config="editorConfig"
                key="text"
              ></ckeditor>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              {{ $t("FORM_INPUT_NAMES.question") }}
              <ckeditor
                v-model="inputData.question"
                :config="editorConfigIntro"
              ></ckeditor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ $t("FORM_INPUT_NAMES.answer") }}
              <ckeditor
                key="answer"
                v-model="inputData.answer"
                :config="editorConfig1"
              ></ckeditor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="handleAddInputData"
                :disabled="
                  inputData.answer.length == 0 || inputData.question.length == 0
                "
              >
                {{ $t("FORMS.add") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-form>
        <v-row
          v-if="
            formModel.translations[selectedLocale.lang] &&
            formModel.translations[selectedLocale.lang].faqs &&
            formModel.translations[selectedLocale.lang].faqs.length > 0
          "
        >
          <v-col
            cols="12"
            v-if="
              formModel.translations[selectedLocale.lang] &&
              formModel.translations[selectedLocale.lang].faqs &&
              formModel.translations[selectedLocale.lang].faqs.length > 0
            "
          >
            <v-expansion-panels focusable>
              <draggable v-model="draggableCards" style="width: 100%">
                <v-expansion-panel v-for="(item, i) in files" :key="item.name">
                  <v-expansion-panel-header>
                    <div v-html="item.question"></div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span v-html="item.answer"></span>
                    <v-spacer></v-spacer>
                    <v-icon
                      color="primary"
                      style="display: block; font-size: 16px; float: right"
                      small
                      @click="deleteItem(i)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </draggable>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { PERMISSION_TO } from "./Faqs";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import draggable from "vuedraggable";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

const TRANSLATED_ATRIBUTES = ["name", "text"];

const INITIAL_INPUT_DATA = { question: "", answer: "" };

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const initialFormData = () => ({
  status: 1,
  translations: {},
  // custom_fields: [],
});

export default {
  name: "FaqForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { draggable, DeleteModalDialog, SnackBarInfoComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfigIntro: {
        height: 100,
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        forcePasteAsPlainText: true,
        extraPlugins: "justify,font,copyformatting",
        // extraPlugins: "divarea,justify",
        // justifyClasses: null,
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig1: {
        forcePasteAsPlainText: true,
        extraPlugins: "justify,font,copyformatting",
        // extraPlugins: "divarea,justify",
        // justifyClasses: null,
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      inputData: Object.assign({}, INITIAL_INPUT_DATA),
      dialogDelete: false,
      itemToDeleteIndex: null,
      files: [],
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.FAQ");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    draggableCards: {
      get() {
        return this.files;
      },
      set(val) {
        this.files = val;
      },
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.files = [
                ...this.formModel.translations[this.selectedLocale.lang].faqs,
              ];
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          // this.inputData = Object.assign({}, INITIAL_INPUT_DATA);
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleAddInputData() {
      if (!this.formModel.translations[this.selectedLocale.lang].faqs) {
        this.formModel.translations[this.selectedLocale.lang].faqs = [];
      }

      this.formModel.translations[this.selectedLocale.lang].faqs.push(
        Object.assign({}, this.inputData)
      );

      this.files = this.formModel.translations[this.selectedLocale.lang].faqs;

      this.files = this.formModel.translations[this.selectedLocale.lang].faqs;

      this.inputData = Object.assign({}, INITIAL_INPUT_DATA);
    },

    deleteItem(i) {
      this.itemToDeleteIndex = i;
      this.dialogDelete = true;
    },

    handleCloseDelete() {
      this.itemToDeleteIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItemConfirm() {
      this.formModel.translations[this.selectedLocale.lang].faqs.splice(
        this.itemToDeleteIndex,
        1
      );

      this.files = this.formModel.translations[this.selectedLocale.lang].faqs;
      this.handleCloseDelete();
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
